import React from 'react'
import classNames from 'classnames'
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles(theme => ({
  container: {
    position: 'relative',
  },
  ribbon: {
    position: 'absolute',
    top: 57,
    left: -5,
    width: 137,
    color: 'white',
    fontSize: '12px',
    transform: 'rotate(-45deg)',
    textAlign: 'center',
    fontWeight: 'bold',
  },
}))

export default ({ ribbon, width, height, ...rest }) => {
  const classes = useStyles()

  return (
    <div {...rest}>
      <div className={classNames([classes.container, {
        width: width,
        height: height,
      }])}>
        <svg width={width || '1em'} height={height || '1em'} fill="none">
          <path d="M133.645 80.843l-53.81 53.81h-55.19l109-109v55.19z" fill="#000" />
          <rect
            x={37}
            y={37}
            width={width || '1em'}
            height={height || '1em'}
            rx={5}
            fill="#fff"
          />
          <path
            d="M22.257 78.825l54.56-54.56a2.093 2.093 0 011.48-.612h51.296c1.864 0 2.798 2.254 1.479 3.572L25.217 133.081c-1.318 1.318-3.572.384-3.572-1.48V80.304c0-.555.22-1.087.612-1.48z"
            fill="#FF6069"
            stroke="#000"
            strokeWidth={3}
          />
        </svg>
        <div className={classes.ribbon}>
          {ribbon}
        </div>
      </div>
    </div>
  )
}
