import Router from 'next/router'

const redirectIfLoggedIn = ({ store, res }, location='/menu') => {
  const state = store.getState()

  if (state.api.profile.default.data) {
    if (res) {
      res.writeHead(307, {
        Location: location,
      })

      res.end()
    } else {
      Router.replace(location)
    }

    return false
  }

  return true
}

export default redirectIfLoggedIn
