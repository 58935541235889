import React from 'react'

const SvgComponent = props => (
  <svg width="1em" height="1em" viewBox="0 0 15 19" fill="none" {...props}>
    <path
      d="M13.874 7.983c0 3.125-3.119 6.601-5.551 9.4a1.213 1.213 0 01-1.808.033C3.947 14.611 1 11.12 1 7.983c0-3.521 2.882-6.376 6.437-6.376s6.437 2.855 6.437 6.376z"
      stroke="#fff"
      strokeWidth={1.801}
    />
    <circle cx={7.436} cy={8.045} r={2.861} stroke="#fff" strokeWidth={1.801} />
  </svg>
)

export default SvgComponent
