import React from 'react'

const SvgComponent = props => (
  <svg width="1em" height="1em" viewBox="0 0 12 7" fill="none" {...props}>
    <path
      d="M1 1.25l5 5 5-5"
      stroke="#fff"
      strokeWidth={1.5}
      strokeLinejoin="round"
    />
  </svg>
)

export default SvgComponent
